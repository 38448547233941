import { createStore } from 'vuex'

export default createStore({
  state () {
    return {
      environment: 'sandbox',
      locale: 'es',
      alerts: null,
      user: null,
      managementWorkspaceID: null,
      managementButtonWarningsUpdate: null,
      manualScale: true,
    }
  }
})

