export default {
    common: {
      add: 'Añadir',
      edit: 'Editar',
      save: 'Guardar',
      cancel: 'Cancelar',
      delete: 'Eliminar',
      search: 'Buscar',
      back: 'Atras',
      complete: 'Completo',
      ok: 'Aceptar',
      clear: 'Vaciar',
      config: 'Configuración',
      confirm: 'Confirmar',
      all: 'Todos',
      close: 'Cerrar',
      home: 'Inicio',
      required: 'Requerido',
      download: 'Descargar',
      replace: 'Reemplazar',
      concat: 'Sumar',
      select: 'Seleccionar',
      daysOfWeek: {
        0: 'Domingo',
        1: 'Lunes',
        2: 'Martes',
        3: 'Miércoles',
        4: 'Jueves',
        5: 'Viernes',
        6: 'Sábado',
      },
    },
    deleteButton: {
      message: '¿Está seguro de que desea eliminar este elemento?',
    },
    login: {
      account: 'Cuenta',
      email: 'Email',
      emailPlaceholder: 'Dirección de email',
      password: 'Contraseña',
      passwordPlaceholder:'Entre su contraseña',
      login: 'Acceder',
      logout: 'Salir',
      signIn: 'Iniciar sesión',
      changePassword: 'Cambiar contraseña',
      oldPassword: 'Actual contraseña',
      newPassword: 'Nueva contraseña',
      confirmPassword: 'Confirmar contraseña',
      forgotLoginPassword: '¿Has olvidado tu contraseña?',
      orLogin: 'o iniciar sesión',
      passwordRecovery: 'Recuperar contraseña',
      messages: {
        userNotFound: 'Usuario o contraseña incorrectos',
        changePasswordError: 'Error',
        required: 'Requerido',
        max20: 'Debe ser de menos de 20 caracteres',
        passwordNotMatch: 'Contraseñas deben ser iguales',
        strengthNotValid: 'La contraseña debe tener al menos 8 caracteres, una mayúscula, una minúscula, un número y un caracter especial.',
        passwordRecoverMailSend:'Se ha enviado un correo para recuperar la contraseña',
      },
    },
    menuForm: {
      name: 'Nombre',
      nameRequired: 'El nombre es requerido',
      max150: 'Nombre debe ser de menos de 150 caracteres',
    },
    system: {
      title: 'System',
      goToManagement: 'Ir a la administración',
      management: 'Administración',
      config: {
        title: 'Configuración',
        co2: 'CO2',
        directCost: 'Coste directo(€/kg)',
      },
      dashboard: {
        title: 'Dashboard',
      },
      workspace: {
        title: 'Workspaces',
        name: 'Nombre',
        deleteWeightData: 'Eliminar registros de peso',
        nameRequired: 'El nombre es requerido',
        max50: 'El nombre debe ser de menos de 50 caracteres',
        subjectKeyMets: 'Asunto',
        emailKeyMets: 'Email',
        relationsKeys: {
          title: 'Relations keys',
          name: 'Nombre',
        },
        shifts: {
          title: 'Servicios',
          name: 'Nombre',
        },
        analyticsCards: {
          title: 'Análisis tarjetas',
          button: 'Botón',
          custom: 'Personalizado',
          name: 'Título',
        },
      },
      users: {
        title: 'Usuarios',
        email: 'Email',
        emailRequired: 'El email es requerido',
        max50: 'El email debe ser de menos de 50 caracteres',
        password: 'Contraseña',
        passwordRequired: 'La contraseña es requerida',
        permissions: 'Permisos',
        emailNotValid: 'Email no válido',
        emailAlreadyExists: 'El email ya existe',
        welcomePath: 'Ruta por defecto',
        required: 'Requerido',
      },
    },
    management: {
      workspace: 'Workspace',
      dashboard: {
        title: 'Dashboard',
        messages: {
          noDataToday: 'No hay datos hoy',
          daysAgo: 'Últimos datos hace %% días',
        },
      },
      diners: {
        title: 'Cubiertos',
        date: 'Fecha',
        shift: 'Turno',
        value: 'Valor',
        messages: {
          valueRequired: 'Cubierto es requerido',
          isNotNumber: 'Cubierto debe ser un número',
          greaterThan0: 'Cubierto debe ser mayor que 0',
          duplicateRecord: 'Registro duplicado',
        },
      },
      keys: {
        title: 'Keys',
        ingredients: 'Ingredientes',
        ingredient: 'Ingrediente',
        percentage: 'Porcentage',
        dishes: 'Platos',
        others: 'Otros',
        name: 'Nombre',
        priceKg: 'Precio por Kg',
        messages: {
          nameRequired: 'El nombre es requerido',
          max50: 'El nombre debe ser de menos de 50 caracteres',
          isNotNumber: 'Debe ser un número',
        },
      },
      buttons: {
        title: 'Botones',
        name: 'Nombre',
        defaultHomeOnSave: 'Inicio por defecto al guardar',
        fieldsRequired: '(*) indica campos requeridos',
        relationsKeys: 'Claves de relación',
        dishesKeys: 'Platos',
        othersKeys: 'Otros',
        ingredientsKeys: 'Ingredientes',
        weightButton: 'Botón de pesaje',
        preConsumerButton: 'Botón de Pre-Consumo',
        tara: 'Tara',
        net: 'Neto',
        gross: 'Bruto',
        price: 'Precio',
        showHide: 'Mostrar ocultos',
        availability: {
          temp: 'Temporal',
          noTemp: 'No temporal',
          alt: 'Alternancia',
          dateStart: 'Fecha inicio',
        },
        messages: {
          isNotNumber: 'Debe ser un número',
          max150: 'El nombre debe ser de menos de 150 caracteres',
          required: 'Requerido',
          noEndWeightButtons: 'Botones sin marcar para pesajes',
          allServices: 'Botón visible en todos los servicios',
          filterServices: 'Botón visible en los servicios seleccionados',
        },
      },
      weightCollection: {
        title: 'Registro de peso',
        selectMenu: 'Selecciona menú',
        selectProduct: 'Selecciona producto',
        weigh: 'Pesar',
        messages: {
          title: '¿Desea continuar?',
          variation: 'Se ha detectado una variación sobre la media normal.',
          weightExist: 'Ya se ha registrado un peso previo de %%'
        },
      },
      stats: {
        title: 'Datos',
      },
      analytics: {
        title: 'Analíticas',
        subtitle: 'Resumen del desperdicio de alimentos',
        noDataToday: 'No hay datos de hoy',
        dataFromTo: 'Datos desde min al max',
        average: 'Media',
        weight: 'Peso',
        diff: 'Diferencia en Kg',
        percentage: 'Porcentaje',
        cards: {
          averageDailyFoodWaste: 'Media diaria de desperdicio',
          foodWastePerCover: 'Comida desperdiciada por cubierto',
          preConsumerFoodWaste: 'Desperdicio de alimentos preconsumo',
          carbonReduction: 'Huella de carbono asociada al desperdicio',
        },
        compare: {
          title: 'Comparador entre dos fechas específicas',
          subtitle: '',
          daysAgo: 'hace %% días',
          date: 'Fecha',
        },
        messages: {
          noData: 'No hay datos para los filtros seleccionados',
        },
      },
    },
    notFound: {
      info: 'La página que estas buscando no existe',
      getMeOutOfHere: '¡Sácame de aquí!',
    },
}
